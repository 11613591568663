<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb" style="height: 45px">
      <el-button
        class="right"
        style="width: 88px; height: 36px"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="'基本信息'"></TitleModule>
      <info-table
        :num="3"
        :tableData="basicInfo"
        :dataValue="formInline"
      ></info-table>
      <TitleModule :title="'设备列表'" style="margin-top: 30px"></TitleModule>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.Equipment_status')"
            width=""
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.equipmentState == 1">
                <font
                  :class="{ red: scope.row.actualState == 2 }"
                  v-if="scope.row.actualState == 2"
                  >离线</font
                >
                <font v-if="scope.row.actualState == 1">在线</font>
              </span>
              <span v-if="scope.row.equipmentState == 2">
                <font>无</font>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Update_time')"
            width=""
            align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.actualStateUpdated ? scope.row.actualStateUpdated : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="140"
            align="center"
            v-if="$route.meta.authority.button.view"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary" size="small"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
import TitleModule from "@/components/titleModule/titleModule";
import i18n from "@/i18n";
export default {
  data() {
    return {
      msg: "",
      loading: false,
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      formInline: {},
      parkingData: [
        {
          parkName: "22222222222",
          operatorName: "3333333333",
          equipment: "52",
          abnormalEquipment: "48",
          equipmentState: "1",
        },
      ],
      tableCols: [
        {
          prop: "equipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
          width: "",
        },
        {
          prop: "equipmentType",
          label: this.$t("list.Equipment_type"),
          width: "",
          formatter: function (row, column, cellValue, index) {
            switch (cellValue) {
              case 1:
                return i18n.t("list.PDA_devices");
              case 2:
                return i18n.t("list.MS_equipment");
              case 3:
                return i18n.t("list.Gun_mechanism_semi_closed");
              case 4:
                return i18n.t("list.Gun_Ball_Machine_Parallel");
              case 5:
                return i18n.t("list.Violation_of_ball_suspension");
              case 6:
                return i18n.t("list.Suspension_equipment");
              case 7:
                return i18n.t("list.Parallel_multi_gun_equipment");
              case 8:
                return i18n.t("list.Median_video_equipment");
              default:
                "-";
                break;
            }
          },
        },
        {
          prop: "parkName",
          label: this.$t("list.location_road"),
          width: "",
        },
        {
          prop: "barCode",
          label: this.$t("list.Correspondin_pole_position_number"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "equipmentState",
          label: this.$t("list.Operation_status"),
          width: "",
          formatter: function (row, column, cellValue, index) {
            switch (cellValue) {
              case 1:
                return i18n.t("list.go_online");
              case 2:
                return i18n.t("list.Offline");
              default:
                "-";
                break;
            }
          },
        },
      ],
      basicInfo: [
        {
          name: "道路名称",
          key: "parkName",
        },
        {
          name: "运营商名称",
          key: "operationName",
        },
        {
          name: "设备总量",
          key: "equipment",
          format: (dataObj, data, dataVal) => {
            // dataObj 是当前对象
            return `${dataVal.normal * 1 + dataVal.abnormal * 1}`;
          },
        },
        {
          name: "在线设备",
          key: "normal",
        },
        {
          name: "离线设备",
          key: "abnormal",
        },
        {
          name: "在线状态",
          key: "isOk",
          format: (dataObj, data, dataVal) => {
            // dataObj 是当前对象
            return `${dataObj == 1 ? "异常" : "正常"}`;
          },
        },
      ],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      let url = "/acb/2.0/equipment/query";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          // console.log(res)
          this.loading = false;
          if (this.tableData == "") {
            this.total = 0;
          }
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
  },
  mounted() {
    this.formInline = this.$route.query;
    this.searchData();
  },
  components: {
    infoTable,
    TitleModule,
  },
};
</script>

<style>
</style>
